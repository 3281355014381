const theme = {
  black: '#333333',
  white: '#fffbee', 
  primaryColor: '#f84970',
  primaryLightColor: '#e6dcb8',
  primaryLightColorTwo: '#f8f5fd',
  secondaryColor: '#161c33', 
  secondaryLightColor: '#e6dcb8',
  tetriaryColor: '#E281CE',
  headingColor: '#161c33', 
  textColor: '#7A848E',  
  shadowColor: 'rgba(0, 0, 0, 0.05)',
  shadowLightColor: '#E6E6E6',
  menuColor: '#161c33',
  menuHoverColor: '#f84970',
  backgroundColor: '#fffbee',
  //backgroundColor: '#fff9e2',
};
export default theme;
